import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import Seo from '../components/layout/Seo';
import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import {IconSearch} from "../components/icons/Icons";
import IconsProductPreloader from "../components/products/IconsProductPreloader";
import HeroImage from "../components/HeroImage";

const InThePressPage = () => {
    const [limit, setLimit] = useState(18);
    const [keyword, setKeyword] = useState('');
    const [appliedKeywords, setAppliedKeywords] = useState([]);

    const { blogs } = useStaticQuery(
        graphql`
            query {
                blogs: allStrapiPage(
                    filter: { press_release: { eq: true } }
                    sort: { order: DESC, fields: updated_at }
                ) {
                    nodes {
                        title
                        meta_title
                        meta_description
                        slug
                        hero_image_url
                        hero_image {
                            url
                        }
                        created_at
                        updated_at
                    }
                }
            }
        `
    );
    const [filteredBlogs, setFilteredBlogs] = useState(blogs.nodes.filter((_c, i) => i < 18));
    const [maxBlogs, setMaxBlogs] = useState(blogs.nodes.length);

    const onSearch = (e) => {
        e.preventDefault();
        setLimit(18)
        setAppliedKeywords(keyword.toLowerCase().split(/\s/))
    }
    const extendLimit = () => {
        setLimit(l => l + 18);
    };
    useEffect(() => {
        let filtered = blogs.nodes.filter(blog=> {
            return appliedKeywords.length == 0 || !appliedKeywords.find(k =>
                blog.title.toLowerCase().indexOf(k) === -1 &&
                blog.meta_title.toLowerCase().indexOf(k) === -1 &&
                blog.meta_description.toLowerCase().indexOf(k) === -1 &&
                blog.slug.toLowerCase().indexOf(k) === -1
            )
        });
        setMaxBlogs(filtered.length);
        setFilteredBlogs(filtered.filter((_c, i) => i < limit))
    }, [limit, appliedKeywords])
    // decide what to do with hero_image_url
    return (
        <Layout
            breadcrumbs={true}
            className="mx-auto"
            canonical={'/in-the-press/'}
            title={'Gray and Sons Press Releases'}
        >
            <IconsProductPreloader />
            <Seo
                title={'Luxury Jewelry Buyers - Sell Us Your Jewelry Press Coverage'}
                canonical={'/in-the-press/'}
                description={
                    'Discover why SellUsYourJewelry has been making headlines with their top-notch service and expertise in the jewelry industry. Visit our website today!'
                }
            />


            <h1 className="p-10 text-center text-2xl uppercase text-link md:text-4xl xl:text-5xl">
              <span className="text-[8rem] text-black">
                In The Press
              </span>
            </h1>
            <section className={"container mx-auto"}>
                <form action={"/blog"} method={"get"} onSubmit={onSearch}>
                    <div className={"flex my-4 w-full border-b border-white justify-self-end"}>
                        <label className={"sr-only"} htmlFor={"search-keywords"}>Search</label>
                        <input type={"text"} name={"q"} placeholder={"Tell us, what are you looking for?"}
                               value={keyword}
                               id={"search-keywords"}
                               onChange={(e) => {
                                   setKeyword(e.target.value)
                               }}
                               className={"flex-grow border border-accent px-4 placeholder-gray-500"}/>
                        <button className={"bg-accent text-white w-auto p-4"}><IconSearch /><span className={"sr-only"}>Search</span>
                        </button>
                    </div>
                </form>
            </section>
            <div className={'container mx-auto flex gap-4'}>
                <section title={'Press Releases Posts list'} className={'flex w-full flex-wrap'}>
                    {filteredBlogs
                        .map((blog) => {
                            return (
                                <article key={blog.slug} className={'block w-full p-4 md:w-1/2 lg:w-1/3'}>
                                    <Link to={"/"+blog.slug+'/'} className={'mx-auto block w-80'}>
                                        { (blog.hero_image_url || blog.hero_image?.url) ? (
                                            <HeroImage
                                                url={blog.hero_image_url || blog.hero_image.url}
                                                alt={blog.meta_title}
                                                className={'h-80 w-80 rounded-2xl object-contain'}
                                            />
                                        ) : (
                                            <StaticImage
                                                src={'../images/top-watch-buyer-img.png'}
                                                alt={blog.meta_title}
                                                className={'h-80 w-80 rounded-2xl object-cover'}
                                            />
                                        )}
                                    </Link>
                                    <h2 className="mx-auto w-80 py-4">
                                        <Link to={'/' + blog.slug + '/'}>{blog.title}</Link>
                                    </h2>
                                    <p className={'mx-auto w-80 pb-4'}>{blog.meta_description}</p>
                                    <Link
                                        className={'mx-auto block w-80'}
                                        aria-label={blog.title}
                                        to={'/' + blog.slug + '/'}
                                    >
                                        Read more
                                    </Link>
                                </article>
                            );
                        })}
                    {
                        limit < maxBlogs &&
                        <button className={'btn-cta w-full p-4'} onClick={extendLimit}>
                            Load More Posts
                        </button>
                    }
                </section>
            </div>
            <section className="flex w-full flex-col justify-center py-10">
                <div className="flex w-full flex-col items-center justify-center ">
                    <a href="tel:+13057706955" className="text-3xl font-bold text-accent hover:text-gray-500">
                        <h3 className="px-10 text-center text-3xl uppercase">
                            <a href={'tel:+13057706955'}>Call Us Now: 305 770 6955</a>
                        </h3>
                    </a>
                    <br />
                    <div className="w-full shadow-xl"> &nbsp;</div>
                    <h2 className="py-10 text-center uppercase text-gray-800">
                        The Purchasing and Consignment Division of Gray &amp; Sons <br />
                    </h2>
                    <br />
                    <a href="https://www.grayandsons.com/catalog-request">
                        <StaticImage
                            src={'../images/svg/gray-and-sons-logo.svg'}
                            alt={'Gray and Sons Jewelers'}
                        />
                    </a>
                    <br /> <br /> <br />
                    <h2 className="w-full py-10 text-center text-2xl font-black uppercase">
                        Be the first to know | SEE OUR CATALOG
                    </h2>
                    <a
                        href="https://www.grayandsons.com/catalog-request"
                        className="flex w-full justify-center"
                    >
                        <StaticImage
                            src={'../images/gray-and-sons-request-catalog-banner.jpeg'}
                            alt={'Gray and Sons Catalog Subscription'}
                        />
                    </a>
                </div>
            </section>
        </Layout>
    );
};

export default InThePressPage;
